import {
	Directive,
	ElementRef,
	HostListener,
	inject,
	Input,
	Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Directive({
	selector: "[appCheckLanguage]",
	standalone: true,
})
export class CheckLanguageDirective {
	@Input("appCheckLanguage") allowType!:
		| "arabic"
		| "english"
		| "numbers"
		| "arabicWithNumbers"
		| "englishWithNumbers"
		| "arabicWithoutNumbers"
		| "englishWithoutNumbers";

	private _el = inject(ElementRef);
	private _renderer = inject(Renderer2);
	private _translate = inject(TranslateService);

	private _regexPatterns = {
		arabic: /^[\u0621-\u064A0-9\s]*$/, // Arabic letters and numbers
		english: /^[A-Za-z\s]*$/, // English letters and spaces
		numbers: /^[0-9]*$/, // Numbers only
		arabicWithNumbers: /^[ء-ي0-9\s]*$/, // Arabic letters and numbers
		englishWithNumbers: /^[a-zA-Z0-9\s]*$/, // English letters and numbers
		arabicWithoutNumbers: /^[\u0621-\u064A\s]*$/, // Arabic letters only (without numbers or symbols)
		englishWithoutNumbers: /^[A-Za-z\s]*$/, // English letters only (without numbers or symbols)
	};

	@HostListener("input", ["$event"]) onInputChange() {
		const inputElement = this._el.nativeElement as HTMLInputElement;
		const initialValue = inputElement.value;

		const pattern = this._regexPatterns[this.allowType];
		inputElement.value = initialValue
			.split("")
			.filter((char) => pattern.test(char))
			.join("");

		if (initialValue !== inputElement.value) {
			this._showErrorMessage();
		} else {
			this._removeErrorMessage();
		}
	}

	private _showErrorMessage() {
		const errorMessageKey = this._getAllowedTypeMessageKey();
		let errorMessage: string;

		this._translate
			.get(errorMessageKey)
			.subscribe((translatedMessage: string) => {
				errorMessage = translatedMessage;

				let errorElement =
					this._el.nativeElement.parentNode.querySelector(
						".error-message"
					);

				if (!errorElement) {
					errorElement = this._renderer.createElement("span");
					this._renderer.addClass(errorElement, "error-message");
					this._renderer.setStyle(errorElement, "color", "#d6303b");
					this._renderer.setStyle(errorElement, "fontSize", "14px");
					this._renderer.appendChild(
						this._el.nativeElement.parentNode,
						errorElement
					);
				}

				this._renderer.setProperty(
					errorElement,
					"innerText",
					errorMessage
				);
			});
	}

	private _removeErrorMessage() {
		const errorElement =
			this._el.nativeElement.parentNode.querySelector(".error-message");

		if (errorElement) {
			this._renderer.removeChild(
				this._el.nativeElement.parentNode,
				errorElement
			);
		}
	}

	private _getAllowedTypeMessageKey() {
		switch (this.allowType) {
			case "arabic":
				return "ERROR_MESSAGES_Directive.ARABIC_LETTERS_ONLY";
			case "english":
				return "ERROR_MESSAGES_Directive.ENGLISH_LETTERS_ONLY";
			case "numbers":
				return "ERROR_MESSAGES_Directive.NUMBERS_ONLY";
			case "arabicWithNumbers":
				return "ERROR_MESSAGES_Directive.ARABIC_LETTERS_AND_NUMBERS_ONLY";
			case "englishWithNumbers":
				return "ERROR_MESSAGES_Directive.ENGLISH_LETTERS_AND_NUMBERS_ONLY";
			case "arabicWithoutNumbers":
				return "ERROR_MESSAGES_Directive.ARABIC_LETTERS_ONLY_NO_NUMBERS";
			case "englishWithoutNumbers":
				return "ERROR_MESSAGES_Directive.ENGLISH_LETTERS_ONLY_NO_NUMBERS";
			default:
				return "";
		}
	}
}
