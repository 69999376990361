<section class="bg-semiLightGray min-w-1/5 min-h-2/5 p-10" *ngIf="display">
	<div class="fixed inset-0 flex items-center justify-center z-50">
		<div class="absolute inset-0 bg-darkBlue opacity-50"></div> <!-- Overlay -->
		<div
			class="w-4/5 min-h-80 relative bg-semiLightGray p-6 rounded-lg shadow-lg d-flex flex items-center justify-center">
			<!-- Close Icon SVG -->
			<div class="__close-icon absolute top-4 right-4">

				<svg height="30px" id="Layer_1" class="cursor-pointer" (click)="onCancelEmitter()"
					style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="30px"
					xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					fill="#ffffff">
					<path
						d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
				</svg>
			</div>
			<div>

				<form [formGroup]="changeMobileForm">
					<div class="h-full w-full flex flex-col">

						<div class="__password">
							<label for="password" class="text-fontColor text-sm font-bold">{{'change_mobile.password' |
								translate}}
								<span class="required">*</span>

							</label>
							<input type="password" placeholder="********" formControlName="password" id="password"
								class="mt-5 border border-fontColor text-base rounded-md text-fontColor  h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
								[ngClass]=""
								>
								<div
							*ngIf="
								changeMobileForm.get('password')?.invalid &&
								(changeMobileForm.get('password')?.dirty ||
								changeMobileForm.get('password')?.touched)
							"
						><small
								class="text-primaryRed text-md mt-2"
								*ngIf="changeMobileForm.get('password')?.hasError(CustomValidationType.required)"
							>
								{{
									"authentication.errors.required"
										| translate
								}}
							</small>
							<small
								class="text-primaryRed text-md mt-2"
								*ngIf="changeMobileForm.get('password')?.hasError(CustomValidationType.patternValidator) &&
								!changeMobileForm.get('password')?.hasError(CustomValidationType.required)"
							>
							{{
								"authentication.errors.password_min_max_length"
									| translate
							}}
							</small>
						</div>
						</div>


						<div class="__mobile">
							<label for="mobile" class="text-fontColor text-sm font-bold mb-5">
								{{
								"change_mobile.new_mobile"
								| translate
								}}
								<span class="required">*</span>

							</label>

							<div class="mt-5 relative">
								<i *ngIf="showTooltip('phone_number')"
									class="material-icons cursor-pointer text-red-800 absolute z-50 right-2 top-2"
									[matTooltip]="getErrorTooltip('phone_number')"
									matTooltipPosition="right">info_outline</i>
								<input type="text" dir="ltr" required formControlName="phone_number" id="mobile"
									class="border border-fontColor text-base rounded-md text-fontColor  h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									placeholder= "{{ 'authentication.login.form.new_password_placeholder' | translate }}"
									
									required
									maxlength="11"
									[appCheckLanguage]="'numbers'" />
									<div
									*ngIf="
										changeMobileForm.get('phone_number')?.invalid &&
										(changeMobileForm.get('phone_number')?.dirty ||
										changeMobileForm.get('phone_number')?.touched)
									"
								>
									<small
										class="text-primaryRed text-md mt-2"
										*ngIf="changeMobileForm.get('phone_number')?.hasError(CustomValidationType.required)"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</small>
									<small class="text-primaryRed text-md mt-2" 
										   *ngIf="changeMobileForm.get('phone_number')?.hasError(CustomValidationType.mobileNumberValidation) &&
										   !changeMobileForm.get('phone_number')?.hasError(CustomValidationType.required)">
										{{
											'authentication.errors.egyptian_mobile_number' | translate
										}}
									</small>
								</div>
							</div>
						</div>


					</div>
				</form>

				<div class="h-full w-full flex justify-between items-center mt-10 gap-2">

					<button (click)="update()" type="submit" [disabled]="changeMobileForm.invalid"
						class="flex flex-row justify-center items-center w-2/4 h-10 bg-lightBlue border border-lightBlue text-base text-fontColor rounded-lg shadow-md ">
						{{ "account.account_management.news_Media.Edit" | translate }}
					</button>


					<button (click)="onCancelEmitter()"
						class="ms-2 flex flex-row justify-center items-center w-2/4 h-10 bg-primaryRed text-base text-fontColor rounded-lg shadow-md hover:primaryRed border border-primaryRed hover:primaryRed">
						{{ "player.cancel" | translate }}
					</button>

				</div>
			</div>
		</div>
	</div>
</section>